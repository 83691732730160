@font-face {
  font-family: Assistant;
  src: url("Assistant-VariableFont_wght.1fdfa3cd.ttf");
}

* {
  box-sizing: border-box;
}

:root {
  --transition-time: .6s;
  --weight-normal: 300;
  --weight-bold: 600;
  --background-color: #f6f7f9;
  --text-color: #212730;
  --text-color-alt: #58616f;
  --accent-color: #324a62;
  --accent-color-alt: #0e69c4;
  --link-color: #f94343;
  --external-link-content-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='hsl(216, 18%25, 16%25)' viewBox='0 0 256 256'%3E%3Cpath d='M128,136v64a8,8,0,0,1-16,0V155.32L45.66,221.66a8,8,0,0,1-11.32-11.32L100.68,144H56a8,8,0,0,1,0-16h64A8,8,0,0,1,128,136ZM208,32H80A16,16,0,0,0,64,48V96a8,8,0,0,0,16,0V48H208V176H160a8,8,0,0,0,0,16h48a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Z'%3E%3C/path%3E%3C/svg%3E");
  font-family: Assistant, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    --background-color: #111418;
    --text-color: #f3f4f7;
    --text-color-alt: #9dacc3;
    --accent-color: #1a2632;
    --accent-color-alt: #1b7dde;
    --external-link-content-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='hsl(216, 18%25, 96%25)' viewBox='0 0 256 256'%3E%3Cpath d='M128,136v64a8,8,0,0,1-16,0V155.32L45.66,221.66a8,8,0,0,1-11.32-11.32L100.68,144H56a8,8,0,0,1,0-16h64A8,8,0,0,1,128,136ZM208,32H80A16,16,0,0,0,64,48V96a8,8,0,0,0,16,0V48H208V176H160a8,8,0,0,0,0,16h48a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Z'%3E%3C/path%3E%3C/svg%3E");
  }
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  border-top: .4rem solid var(--link-color);
  margin: 0;
}

body > header {
  padding: 2rem 0;

  & img.portrait {
    border: .5em solid #fff;
    border-radius: 50%;
    width: 224px;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    box-shadow: 0 0 2em #0003;
  }
}

h1 {
  font-size: 4rem;
  font-weight: var(--weight-normal);
  margin: 2rem 0;
}

h1 ~ p {
  margin: -1rem 0 2rem;
  font-size: 1.5rem;
}

svg.icon {
  fill: var(--text-color);
  transition: fill var(--transition-time);
  width: 1.333rem;
  height: 1.333rem;
  margin-right: .2rem;
  position: relative;
  top: .333rem;
}

svg.icon:has( + a:is(:hover, :focus, :active)) {
  fill: var(--link-color);
}

h2 svg.icon {
  fill: var(--accent-color-alt);
  top: .125rem;
}

@media (width <= 780px) {
  h1 {
    margin: 1.5rem 0;
    font-size: 3rem;
  }

  h1 ~ p {
    margin: -.5rem 0 1rem;
    font-size: 1.25rem;
  }

  body > header img.portrait {
    width: 200px;
  }
}

@media (width <= 500px) {
  body > header img.portrait {
    margin: 1em 0 -1em;
    position: static;
    transform: none;
  }
}

h1 ~ ul {
  padding: 0;
  list-style: none;
}

@media (width >= 880px) {
  h1 ~ ul li {
    display: inline-block;
  }

  h1 ~ ul li:not(:first-child) {
    margin-left: 1rem;
  }
}

@media screen {
  .page {
    margin: 4rem 0;
  }
}

.wrapper {
  max-width: 932px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
}

h2 {
  font-size: 2rem;
  line-height: 1;
  font-weight: var(--weight-normal);
  margin-top: 4rem;
}

h3 {
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: var(--weight-normal);
  margin-top: 2rem;
  margin-bottom: .75rem;
}

h3:has(span:first-child) {
  flex-direction: column;
  display: flex;

  & span:first-child {
    color: var(--text-color-alt);
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: var(--weight-bold);
  }
}

ol {
  counter-reset: ol;
  padding-left: 3rem;
  list-style: none;
}

ol > li {
  counter-increment: ol;
  margin-top: 3rem;
  position: relative;
}

ol > li:before {
  content: counter(ol);
  color: var(--background-color);
  background-color: var(--text-color-alt);
  text-align: center;
  font-size: .9em;
  line-height: 1.5rem;
  font-weight: var(--weight-bold);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1.5rem;
  left: -3rem;
}

a, a:is(:link, :visited) {
  color: var(--link-color);
  font-weight: var(--weight-bold);
  transition: color var(--transition-time), text-decoration-color var(--transition-time), outline-color var(--transition-time);
  border-radius: 1px;
  outline: 2px dotted #0000;
  text-decoration: underline;
}

a:hover {
  color: var(--link-color-current);
  text-decoration-color: #0000;

  &:after {
    color: inherit !important;
  }
}

a:focus {
  color: var(--link-color-current);
  outline-color: var(--link-color);
  text-decoration-color: #0000;

  &:after {
    color: inherit !important;
  }
}

a[href^="#"], a[href^="#"]:is(:link, :visited) {
  font-weight: var(--weight-normal);
  color: var(--text-color);
  text-decoration-color: #0000;
}

p.referenz {
  position: relative;
}

p.referenz:before {
  content: "» ";
  color: var(--accent-color-alt);
  font-size: 1.2rem;
  line-height: 1;
  font-weight: var(--weight-bold);
  content: "» ";
}

.project {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 280px;
  place-items: start;
  gap: 1rem 2rem;
  display: grid;

  & p, & ul {
    grid-column: 1;
    margin: 0;
  }

  & figure {
    grid-area: 1 / 2 / 3;
    width: 100%;
    margin: 0;
    display: block;

    & div, & img, & video {
      max-width: 100%;

      &:not(.noborder) {
        border: .2rem solid var(--text-color-alt);
      }
    }

    & figcaption {
      color: var(--text-color-alt);
    }
  }
}

@media (width <= 720px) {
  .project {
    display: block;

    & figure {
      margin: 1em 0;
    }
  }
}

@media print {
  :root {
    font-size: 10pt;
  }

  .page {
    margin: 0;
  }

  body {
    border-top: none;
  }

  h1, h1 ~ p {
    font-size: 1rem;
    display: inline-block;
  }

  h1:after {
    content: " · ";
  }

  h2 {
    break-after: avoid;
    margin-top: 2rem;
    font-size: 1.75rem;
  }

  h3 {
    margin-top: 2rem;
    font-size: 1.25rem;
  }

  body > header {
    margin: 0;

    & ul {
      list-style: initial;

      & li {
        margin: initial;
        display: block;
        margin: 0 !important;

        &:before {
          display: none;
        }
      }
    }

    & img.portrait {
      border-radius: 0;
      width: 180px;
      top: 1.5rem;
      right: 1.5rem;
      transform: none;
    }
  }

  .page, li {
    break-inside: avoid;
  }

  ol {
    padding-left: 0;
  }

  ol > li:before {
    display: none;
  }

  .project {
    display: block;

    & figure {
      display: none !important;
    }
  }
}

a[href^="http"] {
  position: relative;
}

@media screen {
  a[href^="http"]:after {
    transition: opacity var(--transition-time);
    content: var(--external-link-content-icon);
    opacity: .5;
    width: 1rem;
    height: 1rem;
    margin-right: .25rem;
    display: inline-block;
    position: relative;
    top: .2rem;
    left: .125rem;
  }

  a[href^="http"]:hover:after, a[href^="http"]:focus:after {
    opacity: 1;
  }
}

@media print {
  a[href^="http"][data-footnote]:after {
    content: "[" attr(data-footnote) "]";
    color: var(--accent-color);
    margin-left: .25em;
    font-size: .8rem;
    display: inline-block;
    position: relative;
    top: -.25em;
  }
}

.footnotes {
  font-size: .6em;

  & ul {
    padding-left: 1em;
  }
}

@media screen {
  .footnotes {
    display: none;
  }
}

.signature {
  mix-blend-mode: multiply;
  margin: 1em 0 0 -.5em;
  display: inline-block;
}

@media (prefers-color-scheme: dark) {
  .signature {
    mix-blend-mode: screen;
  }
}

@media print {
  body > header {
    margin-bottom: 4em !important;
  }
}
/*# sourceMappingURL=index.f8bd4a03.css.map */
