@font-face {
  font-family: "Assistant";
  src: url(./Assistant-VariableFont_wght.ttf);
}

* {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
  font-family: "Assistant", Arial, sans-serif;
  line-height: 1.5;
  --transition-time: 600ms;
  --weight-normal: 300;
  --weight-bold: 600;
  /* https://colorhunt.co/palette/dddddd22283130475ef05454*/
  --background-color: hsl(220, 20%, 97%);
  --text-color: hsl(216, 18%, 16%);
  --text-color-alt: hsl(216, 12%, 39%);
  --accent-color: hsl(210, 32%, 29%);
  --accent-color-alt: hsl(210, 87%, 41%);
  --link-color: hsl(0, 94%, 62%);
  --external-link-content-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='hsl(216, 18%25, 16%25)' viewBox='0 0 256 256'%3E%3Cpath d='M128,136v64a8,8,0,0,1-16,0V155.32L45.66,221.66a8,8,0,0,1-11.32-11.32L100.68,144H56a8,8,0,0,1,0-16h64A8,8,0,0,1,128,136ZM208,32H80A16,16,0,0,0,64,48V96a8,8,0,0,0,16,0V48H208V176H160a8,8,0,0,0,0,16h48a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Z'%3E%3C/path%3E%3C/svg%3E");
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    --background-color: hsl(214, 17%, 8%);
    --text-color: hsl(216, 18%, 96%);
    --text-color-alt: hsl(216, 24%, 69%);
    --accent-color: hsl(210, 32%, 15%);
    --accent-color-alt: hsl(210, 78%, 49%);
    --external-link-content-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='hsl(216, 18%25, 96%25)' viewBox='0 0 256 256'%3E%3Cpath d='M128,136v64a8,8,0,0,1-16,0V155.32L45.66,221.66a8,8,0,0,1-11.32-11.32L100.68,144H56a8,8,0,0,1,0-16h64A8,8,0,0,1,128,136ZM208,32H80A16,16,0,0,0,64,48V96a8,8,0,0,0,16,0V48H208V176H160a8,8,0,0,0,0,16h48a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Z'%3E%3C/path%3E%3C/svg%3E");
  }
}

body {
  margin: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  border-top: 0.4rem solid var(--link-color);
}

body > header {
  padding: 2rem 0;
  img.portrait {
    position: absolute;
    border-radius: 50%;
    top: 50%;
    right: 1rem;
    width: 224px;
    transform: translateY(-50%);
    border: 0.5em solid white;
    box-shadow: 0 0 2em rgba(0, 0, 0, 0.2);
  }
}

h1 {
  margin: 2rem 0;
  font-size: 4rem;
  font-weight: var(--weight-normal);
}

h1 ~ p {
  font-size: 1.5rem;
  margin: -1rem 0 2rem 0;
}

svg.icon {
  fill: var(--text-color);
  transition: fill var(--transition-time);
  width: 1.333rem;
  height: 1.333rem;
  margin-right: 0.2rem;
  position: relative;
  top: 0.333rem;
}

svg.icon:has(+ a:is(:hover, :focus, :active)) {
  fill: var(--link-color);
}

h2 svg.icon {
  top: 0.125rem;
  fill: var(--accent-color-alt);
}

@media (max-width: 780px) {
  h1 {
    margin: 1.5rem 0;
    font-size: 3rem;
  }
  h1 ~ p {
    font-size: 1.25rem;
    margin: -0.5rem 0 1rem 0;
  }
  body > header img.portrait {
    width: 200px;
  }
}

@media (max-width: 500px) {
  body > header img.portrait {
    position: static;
    transform: none;
    margin: 1em 0 -1em 0;
  }
}

h1 ~ ul {
  padding: 0;
  list-style: none;
}
@media (min-width: 880px) {
  h1 ~ ul li {
    display: inline-block;
  }
  h1 ~ ul li:not(:first-child) {
    margin-left: 1rem;
  }
}

@media screen {
  .page {
    margin: 4rem 0;
  }
}

.wrapper {
  max-width: 932px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
}

h2 {
  font-size: 2rem;
  margin-top: 4rem;
  line-height: 1;
  font-weight: var(--weight-normal);
}

h3 {
  font-size: 1.5rem;
  line-height: 1.25;
  margin-top: 2rem;
  margin-bottom: 0.75rem;
  font-weight: var(--weight-normal);
}

h3:has(span:first-child) {
  display: flex;
  flex-direction: column;
  span:first-child {
    color: var(--text-color-alt);
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: var(--weight-bold);
  }
}

ol {
  list-style: none;
  counter-reset: ol;
  padding-left: 3rem;
}
ol > li {
  position: relative;
  counter-increment: ol;
  margin-top: 3rem;
}
ol > li::before {
  content: counter(ol);
  position: absolute;
  top: 1.5rem;
  left: -3rem;
  height: 1.5rem;
  width: 1.5rem;
  color: var(--background-color);
  background-color: var(--text-color-alt);
  border-radius: 50%;
  text-align: center;
  line-height: 1.5rem;
  font-size: 0.9em;
  font-weight: var(--weight-bold);
}

a,
a:is(:link, :visited) {
  color: var(--link-color);
  font-weight: var(--weight-bold);
  text-decoration: underline;
  outline: 2px dotted transparent;
  border-radius: 1px;
  transition: color var(--transition-time),
    text-decoration-color var(--transition-time),
    outline-color var(--transition-time);
}
a:hover {
  color: var(--link-color-current);
  text-decoration-color: transparent;
  &::after {
    color: inherit !important;
  }
}
a:focus {
  color: var(--link-color-current);
  outline-color: var(--link-color);
  text-decoration-color: transparent;
  &::after {
    color: inherit !important;
  }
}

a[href^="#"],
a[href^="#"]:is(:link, :visited) {
  font-weight: var(--weight-normal);
  color: var(--text-color);
  text-decoration-color: transparent;
}

p.referenz {
  position: relative;
}
p.referenz::before {
  content: "» ";
  font-size: 1.2rem;
  line-height: 1;
  color: var(--accent-color-alt);
  font-weight: var(--weight-bold);
}
p.referenz::before {
  content: "» ";
}

.project {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-template-rows: auto auto;
  gap: 1rem 2rem;
  align-items: start;
  justify-items: start;
  p,
  ul {
    grid-column: 1;
    margin: 0;
  }
  figure {
    display: block;
    margin: 0;
    width: 100%;
    grid-column: 2;
    grid-row: 1 / 3;
    div,
    img,
    video {
      max-width: 100%;
      &:not(.noborder) {
        border: 0.2rem solid var(--text-color-alt);
      }
    }
    figcaption {
      color: var(--text-color-alt);
    }
  }
}

@media (max-width: 720px) {
  .project {
    display: block;
    figure {
      margin: 1em 0;
    }
  }
}

@media print {
  :root {
    font-size: 10pt;
  }
  .page {
    margin: 0;
  }
  body {
    border-top: none;
  }
  h1,
  h1 ~ p {
    font-size: 1rem;
    display: inline-block;
  }
  h1::after {
    content: " · ";
  }
  h2 {
    font-size: 1.75rem;
    margin-top: 2rem;
    break-after: avoid;
  }
  h3 {
    font-size: 1.25rem;
    margin-top: 2rem;
  }
  body > header {
    margin: 0;
    ul {
      list-style: initial;
      li {
        display: block;
        margin: initial;
        margin: 0 !important;
        &::before {
          display: none;
        }
      }
    }
    img.portrait {
      width: 180px;
      top: 1.5rem;
      right: 1.5rem;
      transform: none;
      border-radius: 0;
    }
  }
  .page,
  li {
    break-inside: avoid;
  }
  ol {
    padding-left: 0;
  }
  ol > li::before {
    display: none;
  }
  .project {
    display: block;
    figure {
      display: none !important;
    }
  }
}

a[href^="http"] {
  position: relative;
}

@media screen {
  a[href^="http"]::after {
    transition: opacity var(--transition-time);
    content: var(--external-link-content-icon);
    display: inline-block;
    margin-right: 0.25rem;
    width: 1rem;
    height: 1rem;
    position: relative;
    top: 0.2rem;
    left: 0.125rem;
    opacity: 0.5;
  }
  a[href^="http"]:hover::after,
  a[href^="http"]:focus::after {
    opacity: 1;
  }
}

@media print {
  a[href^="http"][data-footnote]::after {
    content: "[" attr(data-footnote) "]";
    display: inline-block;
    margin-left: 0.25em;
    position: relative;
    font-size: 0.8rem;
    top: -0.25em;
    color: var(--accent-color);
  }
}

.footnotes {
  font-size: 0.6em;
  ul {
    padding-left: 1em;
  }
}

@media screen {
  .footnotes {
    display: none;
  }
}

.signature {
  display: inline-block;
  margin: 1em 0 0 -0.5em;
  mix-blend-mode: multiply;
}

@media (prefers-color-scheme: dark) {
  .signature {
    mix-blend-mode: screen;
  }
}

@media print {
  body > header {
    margin-bottom: 4em !important;
  }
}
